<template>
  <div class="row">
    <div class="col-12  c g">
        <div class="card card-body">
            <div class="row">
                <div class="col-12 col-lg-2">
                    <div class="form-group">
                    <label for="">اختر التاريخ</label>
                    <input type="date"
                        class="form-control" v-model="date" id="ddate" @change="getReport()">
                    </div>
                </div>
                <div class="col-12 col-lg-2">
                    <div class="form-group">
                        <label>اختر فصل معين</label>
                        <select class="form-control" v-model="classname" @change="getReport()">
                            <option :value="null">الكل</option>
                            <template v-for="classname in classes">
                                <option :value="classname.title + '-all'" :key="classname.title">{{classname.title}} - كل الفصول</option>
                                <option v-for="classroom in classname.classrooms" :key="classroom" :value="classname.title + '-' + classroom">{{classname.title}} - {{ classroom }}</option>
                            </template>
                        </select>
                    </div>
                </div>
                <div class="col-12 col-lg-2">
                    <div class="form-group">
                        <label>اختر حالة معينة</label>
                        <select class="form-control" v-model="status" @change="getReport()">
                            <option :value="null">الكل</option>
                                <option value="0">خارج وقت الدوام</option>
                                <option value="1">الحضور</option>
                                <option value="2">التأخر</option>
                                <option value="1,2">الحضور والتأخر</option>
                                <option value="3">غائب</option>
                                <option value="5">غائب بعذر</option>
                                <option value="ex">الإستئذان</option>
                        </select>
                    </div>
                </div>
                <div class="col-12 col-lg-2">
                    <br>
                    <button @click="$router.push('/_students/undirect-send/' + date)" class="btn btn-success btn-block">
                        <i class="fa fa-paper-plane"></i>
                        ارسال الغياب والتأخر
                    </button>
                </div>
                <div class="col-12 col-lg-2">
                    <br>
                    <b-dropdown style="width: 100%"
                        id="dropdown-1"
                        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                        text="نظام نور"
                        variant="primary"
                    >
                        <b-dropdown-item v-b-modal.noor-ghiab>
                            <i class="fa fa-user-times"></i>
                            اضافة الغياب لنور
                        </b-dropdown-item>
                        <b-dropdown-item v-b-modal.noor-late>
                            <i class="fa fa-clock-o"></i>
                            اضافة التأخر لنور
                        </b-dropdown-item>
                    </b-dropdown>
                </div>
                <div class="col-12 col-lg-1">
                    <br>
                    <b-dropdown style="width: 100%"
                        id="dropdown-1" dropleft
                        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                        text="خيارات"
                        variant="secondary"
                    >
                        <b-dropdown-item v-b-modal.reason-bulk>
                            <i class="fa fa-check-square"></i>
                            اضافة عذر جماعي
                        </b-dropdown-item>
                        <b-dropdown-item v-b-modal.repair>
                            <i class="fa fa-wrench"></i>
                            تصحيح التقرير
                        </b-dropdown-item>
                    </b-dropdown>
                </div>
                <div class="col-12 text-center g" v-if="loading">
                    <img :src="require('@/assets/images/loading.svg')" style="width: 100px;">
                    <br>
                    جاري العرض...
                </div>
            </div>
        </div>
    </div>
    <div class="col-12 g">
        <div class="card card-body">
            <div class="col-12 table-responsive">
                <div class="col-12 text-left g hidemex">
                    <input type="text" placeholder="ابحث هنا..." @change="searchStudent()" id="qq" v-model="q" style="border: 1px solid #999; border-radius: 3px; padding: 7px; width: 200px;max-width: 80%;">
                    <img :src="require('@/assets/images/loading.svg')" style="width: 40px;" v-if="show_search_loading">
                    &nbsp;
                    <b-dropdown style=""
                        id="dropdown-1" dropleft 
                        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                        text="طباعة"
                        variant="warning"
                    >
                        <b-dropdown-item @click="print()">
                            <i class="fa fa-print"></i>
                            طباعة الصفحة المعروضة
                        </b-dropdown-item>
                        <b-dropdown-item @click="print('1')">
                            <i class="fa fa-check"></i>
                            طباعة الحضور
                        </b-dropdown-item>
                        <b-dropdown-item @click="print('2')">
                            <i class="fa fa-clock-o"></i>
                            طباعة التأخر
                        </b-dropdown-item>
                        <b-dropdown-item @click="print('1,2')">
                            <i class="fa fa-users"></i>
                            طباعة الحضور والتأخر
                        </b-dropdown-item>
                        <b-dropdown-item @click="print('3')">
                            <i class="fa fa-user-times"></i>
                            طباعة الغياب بدون عذر
                        </b-dropdown-item>
                        <b-dropdown-item @click="print('5')">
                            <i class="fa fa-user"></i>
                            طباعة الغياب بعذر
                        </b-dropdown-item>
                        <b-dropdown-item @click="print('ex')">
                            <i class="fa fa-user"></i>
                            طباعة المستأذنين
                        </b-dropdown-item>
                    </b-dropdown>
                    &nbsp;
                    <button class="btn btn-success" @click="exportExcel()">
                        <i class="fa fa-file"></i>
                        تصدير الى Excel اكسل
                    </button>
                </div>
                <div v-if="pending > 0">
                    <div class="alert alert-primary text-center g" style="padding: 40px">
                        <img :src="require('@/assets/images/loading-server.gif')" alt="" style="max-width: 100%">
                        <br>
                        <h5 class="g">
                            جاري الآن معالجة بعض السجلات,
                            جاري معالجة ( {{ pending }} ) تقرير...
                            <br>
                        </h5>
                        <i class="text-muted">ستيم الانتهاء بعد حوالي {{ after }} ثانية.</i>
                        <br><br>
                        <button class="btn btn-primary" @click="getReport()">
                            <i class="fa fa-refresh"></i>
                            اعادة المحاولة
                        </button>&nbsp;
                        <button class="btn btn-danger" @click="pending=0">
                            <i class="fa fa-eye"></i>
                            عرض التقرير على اي حال
                        </button>
                     </div>
                </div>
                <table class="table table-hover table-bordered table-sm" id="table" v-if="pending == 0">
                    <thead>
                        <th class="">م</th>
                        <th>
                            <a href="javascript:;" @click="ordertable('name')">الطالب <i class="fa fa-sort"></i></a>
                        </th>
                        <th>
                            <a href="javascript:;" @click="ordertable('classname')">الصف <i class="fa fa-sort"></i></a>
                        </th>
                        <th>
                            <a href="javascript:;" @click="ordertable('classroom')">الفصل <i class="fa fa-sort"></i></a>
                        </th>
                        <th>
                            <a href="javascript:;" @click="ordertable('status')">الحالة <i class="fa fa-sort"></i></a>
                        </th>
                        <th>
                            <a href="javascript:;" @click="ordertable('time')">توقيت الحضور <i class="fa fa-sort"></i></a>
                        </th>
                        <th>
                            توقيت الانصراف   
                        </th>
                        <th class="hidemex">
                            التفاصيل 
                        </th>
                    </thead>
                    <tbody>
                        <tr v-for="(report, i) in reports" :key="i" :status="report.status" :ex="ex(report.leave)">
                            <td class="">{{ i+1 }}</td>
                            <td>
                                {{ report.name }}
                            </td>
                            <td>
                                {{ report.classname }}
                            </td>
                            <td>
                                {{ report.classroom }}
                            </td>
                            <td>
                                <span v-if="!report.editStatus" @click="report.editStatus = !report.editStatus; refreshArr()" class="hoverme" title="اضغط للتعديل">
                                    <span class="badge btn-sm bg-secondary" v-if="report.status == '0'">
                                        <i class="fa fa-clock-o"></i> خارج وقت الدوام
                                    </span>
                                    <span class="badge btn-sm bg-success" v-if="report.status == '1'">
                                        <i class="fa fa-check"></i> حاضر
                                    </span>
                                    <span class="badge btn-sm bg-warning" v-if="report.status == '2'">
                                        <i class="fa fa-clock-o"></i> متأخر
                                    </span>
                                    <span class="badge btn-sm bg-danger" v-if="report.status == '3'">
                                        <i class="fa fa-times"></i> غائب
                                    </span>
                                    <span class="badge btn-sm bg-info" v-if="report.status == '5'">
                                        <i class="fa fa-times"></i> غائب بعذر
                                    </span>
                                    <span class="badge btn-sm bg-primary" v-if="report.status == '6'">
                                        <i class="fa fa-info"></i> اجازة
                                    </span>
                                </span>
                                <select v-model="report.status" v-if="report.editStatus" @change="upldateReport(report, report.time, report.join._id)">
                                    <option value="0">خارج وقت الدوام</option>
                                    <option value="1">حاضر</option>
                                    <option value="2">متأخر</option>
                                    <option value="3">غائب</option>
                                    <option value="5">غائب بعذر</option>
                                </select>
                                <span v-if="report.status == '5' && report.editStatus">
                                    <br>
                                    <input v-if="false" type="text" @change="upldateReport(report, report.time, report.join._id)" v-model="report.reason" placeholder="العذر هنا...">
                                    <select @change="upldateReport(report, report.time, report.join._id)" v-model="report.reason">
                                        <option value="">-- اختر العذر --</option>
                                        <option v-for="sx in reasons" :value="sx" :key="sx">{{ sx }}</option>
                                    </select>
                                </span>
                                <span v-if="!report.editStatus && report.status == '5'" style="white-space: normal; "><br>{{ report.reason }}</span>
                            </td>
                            <td>
                                <span v-if="report.join">
                                    <span v-if="report.join.status">
                                        <span v-if="!report.join.editme" @click="report.join.editme = !report.join.editme; refreshArr()"><span class="rr">{{ report.join.time }}</span> <a href="javascript:;"><i class="fa fa-edit"></i></a></span>
                                        <input v-if="report.join.editme" v-model="report.join.time" @change="report.join.editme = !report.join.editme; upldateReport(report, report.join.time, report.join._id)" type="datetime-local">
                                    </span>
                                </span>
                            </td>
                            <td>
                                <span v-if="report.leave">
                                    <span v-if="report.leave.status">
                                        <span v-if="!report.leave.editme" @click="report.leave.editme = !report.leave.editme; refreshArr()">
                                            {{ report.leave.time }} 
                                            <div>
                                                <div class="addme">
                                                    <i class="text-muted" v-if="report.leave.endex">
                                                        <i class="fa fa-sign-out"></i>
                                                        إستئذان
                                                    </i>
                                                </div>
                                            </div>
                                        </span>
                                        <input v-if="report.leave.editme" v-model="report.leave.time" @change="report.leave.editme = !report.leave.editme; upldateReport(report.leave, report.leave.time, report.leave._id)" type="datetime-local">
                                    </span>
                                </span>
                            </td>
                            <td class="hidemex" v-if="!exporting">
                                <span class="badge btn-sm bg-secondary" style="cursor: pointer;" v-b-modal.details @click="currentReport = report">
                                    <i class="fa fa-info"></i> التفاصيل
                                </span>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
    <b-modal id="reason-bulk" title="عذر جماعي" hide-footer>
        <span>العذر <span class="text-danger">*</span></span>
        <select v-model="bulk_reason_selected" class="form-control">
            <option value="">-- اختر العذر --</option>
            <option v-for="sx in reasons" :value="sx" :key="sx">{{ sx }}</option>
        </select>
        <br>
        <div class="col-12 table-responsive">
            <table class="table table-hover table-bordered table-sm">
                <thead>
                    <th>
                        اختر الطلاب
                    </th>
                    <th>
                        الحالة
                    </th>
                </thead>
                <tbody>
                    <template v-for="r in reports">
                        <tr :key="r.number" v-if="r.status == '5' || r.status == '3'">
                            <td>
                                <div class="form-check">
                                  <label class="form-check-label">
                                    <input type="checkbox" class="form-check-input" :value="r" v-model="bulk_reason_selected_students">
                                    {{ r.name }}
                                  </label>
                                </div>
                            </td>
                            <td>
                                <span class="badge btn-sm bg-danger" v-if="r.status == '3'">
                                    <i class="fa fa-times"></i> غائب
                                </span>
                                <span class="badge btn-sm bg-info" v-if="r.status == '5'">
                                    <i class="fa fa-times"></i> غائب بعذر
                                </span>
                                <br>
                                {{ r.reason }}
                            </td>
                        </tr>
                    </template>
                </tbody>
            </table>
        </div>
        <div class="col-12 text-center g">
            <button class="btn btn-primary" @click="addBulkReasonNow()">
                اضافة الاعذار الان
            </button>
        </div>
    </b-modal>
    <b-modal id="details" title="التفاصيل" hide-footer>
        <div v-if="currentReport.join">
            <div class="alert g alert-warning text-center" v-if="!currentReport.join.time">
                لا يوجد تفاصيل
            </div>
            <div v-if="currentReport.join.time">
                <p>تفاصيل الحضور</p>
                <table class="table table-hover table-bordered">
                    <tbody>
                        <tr>
                            <td>
                                رقم الهوية
                            </td>
                            <td>
                                {{ currentReport.join.number }}
                            </td>
                        </tr>
                        <tr>
                            <td>
                                التوقيت
                            </td>
                            <td>
                                {{ currentReport.join.time }}
                            </td>
                        </tr>
                        <tr>
                            <td>
                                توقيت المعالجة
                            </td>
                            <td>
                                {{ currentReport.join.process_time }}
                            </td>
                        </tr>
                        <tr>
                            <td>
                                المشرف
                            </td>
                            <td>
                                {{ currentReport.join.admin_id }}
                            </td>
                        </tr>
                        <tr>
                            <td>
                                تحضير من خلال
                            </td>
                            <td>
                                {{ currentReport.join.from.replace("bulk", 'التحضير الجماعي')
                                .replace("barcode", 'الباركود')
                                .replace("manual", 'يدوياً')
                                .replace("attend-before", 'تحضير الايام السابقة')
                                .replace("fp", 'البصمة') }}
                            </td>
                        </tr>
                        <tr>
                            <td>
                                الجهاز
                            </td>
                            <td>
                                {{ currentReport.join.fp }}
                            </td>
                        </tr><tr>
                        <td>الإعدادات</td>
                        <td>
                            <li>
                                توقيت الحضور: {{ currentReport.join.settings.start_date }}
                            </li>
                            <li>
                                توقيت التأخر: {{ currentReport.join.settings.late_date }}
                            </li>
                            <li>
                                توقيت الغياب: {{ currentReport.join.settings.ghiab_date }}
                            </li>
                            <li>
                                توقيت الإنصراف: {{ currentReport.join.settings.end_date }}
                            </li>
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <div v-if="currentReport.leave">
            <div v-if="currentReport.leave.time">
                <hr>
                <p>تفاصيل الانصراف</p>
                <table class="table table-hover table-bordered">
                    <tbody>
                        <tr>
                            <td>
                                رقم الهوية
                            </td>
                            <td>
                                {{ currentReport.leave.number }}
                            </td>
                        </tr>
                        <tr>
                            <td>
                                التوقيت
                            </td>
                            <td>
                                {{ currentReport.leave.time }}
                            </td>
                        </tr>
                        <tr>
                            <td>
                                توقيت المعالجة
                            </td>
                            <td>
                                {{ currentReport.leave.process_time }}
                            </td>
                        </tr>
                        <tr>
                            <td>
                                المشرف
                            </td>
                            <td>
                                {{ currentReport.leave.admin_id }}
                            </td>
                        </tr>
                        <tr>
                            <td>
                                تحضير من خلال
                            </td>
                            <td>
                                {{ currentReport.leave.from.replace("bulk", 'التحضير الجماعي')
                                .replace("barcode", 'الباركود')
                                .replace("manual", 'يدوياً')
                                .replace("attend-before", 'تحضير الايام السابقة')
                                .replace("fp", 'البصمة').replace("endex", 'الاستئذان') }}
                            </td>
                        </tr>
                        <tr>
                            <td>
                                الجهاز
                            </td>
                            <td>
                                {{ currentReport.leave.fp }}
                            </td>
                        </tr><tr>
                        <td>الإعدادات</td>
                        <td>
                            <li>
                                توقيت الحضور: {{ currentReport.leave.settings.start_date }}
                            </li>
                            <li>
                                توقيت التأخر: {{ currentReport.leave.settings.late_date }}
                            </li>
                            <li>
                                توقيت الغياب: {{ currentReport.leave.settings.ghiab_date }}
                            </li>
                            <li>
                                توقيت الإنصراف: {{ currentReport.leave.settings.end_date }}
                            </li>
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </b-modal>
    <b-modal id="noor-ghiab" title="اضافة الغياب والتأخر لنور" hide-footer size="lg">
        <div class="col-12 g addGhiab" id="addGhiab">
            <div class="col-12 text-center">
                <div class="alert alert-warning text-center g addGhiabN__a55ca6xx5a_">
                    <p class="text-dark">
                        برجاء تثبيت اداة اضافة الغياب <a href="/extension">من هنا</a>
                    </p>
                </div>
                <button class="btn btn-lg btn-success addGhiabN__a55ca6xx5a dnone" @click="addGhiabN__a55ca6xx5a()">
                    إضافة الغياب الآن
                </button>
            </div>
            <hr>
            <div class="form-group">
                <h5 for="">نوع حساب نور (المرحلة)</h5>
                <select class="form-control" style="border: dashed 2px red" name="" id="stage">
                    <option value="mt">متوسط</option>
                    <option value="eb">ابتدائي</option>
                    <option value="mas">مسارات</option>
                </select>
            </div>
            <hr>
            <div class="form-group">
                <h5 for="">تغيير الكل إلى:</h5>
                <select class="form-control" style="border: solid 2px red;" id="changeAllNoorStatuses" @change="changeAllNoorStatuses()">
                    <option value="-99">-- اختر --</option>
                    <option value="141,">الغياب بعذر </option>
						<option value="48,">الغياب بدون عذر مقبول عن كل يوم دراسي خلال الفصل الدراسي </option>
						<option value="222,">الغياب بدون عذر مقبول</option>
						<option value="227,">الغياب بعذر</option>
						<option value="142,">الغياب بعذر </option>
						<option value="600627,">الغياب بدون عذر مقبول عن كل يوم دراسي خلال الفصل الدراسي</option>
						<option value="800667,">الغياب بعذر مقبول عبر منصة مدرستي</option>
						<option value="1201153,">الغياب دون عذر مقبول عبر منصة مدرستي</option>
                </select>
            </div>
            <hr>
            <div class="col-12 table-responsive">
                <table class="table table-hover table-striped table-bordered">
                    <thead>
                        <tr>
                            <th>
                            الطالب
                            </th>
                            <th>
                                نوع المخالفة
                            </th>
                        </tr>
                    </thead>
                    <tbody class="noorStudents">
                        <template v-for="(report) in reports">
                            <tr :key="report.name" v-if="report.status == '3' || report.status == '5'">
                                <td>
                                    {{ report.name  }}
                                </td>
                                <td>
                                    <select class="form-control form-control-sm">
                                        <option value="48," :selected="report.status == '3' ? true : false">الغياب بدون عذر مقبول عن كل يوم دراسي خلال الفصل الدراسي  </option>
                                        <option value="227," :selected="report.status == '5' ? true : false">الغياب بعذر </option>
                                        <option value="141,">الغياب بعذر </option>
						<option value="222,">الغياب بدون عذر مقبول</option>
						<option value="142,">الغياب بعذر </option>
						<option value="600627,">الغياب بدون عذر مقبول عن كل يوم دراسي خلال الفصل الدراسي</option>
						<option value="800667,">الغياب بعذر مقبول عبر منصة مدرستي</option>
						<option value="1201153,">الغياب دون عذر مقبول عبر منصة مدرستي</option>
                                    </select>
                                </td>
                            </tr>
                        </template>
                    </tbody>
                </table>
            </div>
        </div>
    </b-modal>
    <b-modal id="noor-late" title="اضافة الغياب والتأخر لنور" hide-footer size="lg">
        <div class="col-12 g" id="addLate">
            <div class="col-12 text-center">
                <div class="alert alert-warning text-center g addGhiabN__a55ca6xx5a2_">
                    <p class="text-dark">
                        برجاء تثبيت اداة اضافة الغياب <a href="/extension">من هنا</a>
                    </p>
                </div>
                <button class="btn btn-lg btn-success addGhiabN__a55ca6xx5a2 dnone" @click="addGhiabN__a55ca6xx5a2()">
                    إضافة التأخر الآن
                </button>
            </div>
            <hr>
            <div class="form-group">
                <h5 for="">نوع حساب نور (المرحلة)</h5>
                <select class="form-control" style="border: dashed 2px red" name="" id="stage2">
                    <option value="mt">متوسط</option>
                    <option value="eb">ابتدائي</option>
                    <option value="mas">مسارات</option>
                </select>
            </div>
            <hr>
            <div class="form-group">
                <h5 for="">تغيير الكل إلى:</h5>
                <select class="form-control" style="border: solid 2px red;" id="changeAllNoorStatuses2" @change="changeAllNoorStatuses2()">
                    <option value="-99">-- اختر --</option>
                    <option value="1201099,الدرجة الأولى">التأخر  أو العبث  أثناء الاصطفاف الصباحي.</option>
                </select>
            </div>
            <hr>
            <div class="col-12 table-responsive">
                <table class="table table-hover table-striped table-bordered">
                    <thead>
                        <tr>
                            <th>
                                الطالب
                            </th>
                            <th>
                                نوع المخالفة
                            </th>
                        </tr>
                    </thead>
                    <tbody class="noorStudents">
                        <template v-for="(report) in reports">
                            <tr :key="report.name" v-if="report.status == '2'">
                                <td>
                                    {{ report.name  }}
                                </td>
                                <td>
                                    <select class="form-control form-control-sm">
                                        <option value="1201099,الدرجة الأولى">التأخر  أو العبث  أثناء الاصطفاف الصباحي.</option>
                                    </select>
                                </td>
                            </tr>
                        </template>
                    </tbody>
                </table>
            </div>
        </div>
    </b-modal>
    <b-modal id="repair" :title="'تصحيح التقرير بتاريخ ' + date" hide-footer>
        <div class="col-12 text-center g">
            <img :src="require('@/assets/images/repair.png')" style="width: 100px;" alt="">
            <br><br>
            <p>
                تصحيح التقرير، في حالة وجود خلل في توقيت التقارير يمكنك تصحيح التقرير لليوم الذي كان به مشكلة بعد تغيير اعدادات الطلاب. ويتم إعادة جميع التقارير المعدلة يدوياً إلى أصلها.
            </p>
            <button class="btn btn-danger" @click="repair()">
                <i class="fa fa-check"></i>
                تصحيح التقرير الآن
            </button>
        </div>
    </b-modal>
    <div class="dnone htmlme"></div>
  </div>
</template>

<script>
var XLSX = require("xlsx");
import Ripple from "vue-ripple-directive";
import { VBModal, BModal, BDropdown, BDropdownItem } from "bootstrap-vue";
export default {
    components: {
        BDropdown,
        BDropdownItem,
        BModal
    },
    directives: {
        Ripple,
        "b-modal": VBModal,
    },
    data(){
        return {
            exporting: false,
            loading: false,
            show_search_loading: false,
            date: new Date().toISOString().split("T")[0],
            reports: [],
            user: JSON.parse(localStorage.getItem('user')),
            classname: null,
            classes: [],
            status: null,
            currentReport: {},
            bulk_reason_selected: "",
            bulk_reason_selected_students: [],
            pending: 0,
            after: 0,
            q: null,
            inter: null,
            xa: -1,
            xb: 1,
            last_sort: "f",
            inter2: null,
            reasons: [
                "اختبار قياس",
                "مراجعة بوالدته",
                "مراجعة دائرة حكومية",
                "مرافق مع مريض",
                "مريض حسب افادة والده",
                "مريض ولديه مشهد مراجعة",
                "مريض ولديه اجازة مرضية",
                "مستأذن له ولي أمره",
                "مشاركة مع النادي الرياضي",
                "منوم في المستشفى",
                "موعد بالأحوال المدنية",
                "موعد طبي",
            ]
        }
    },
    beforeDestroy(){
        if(this.inter){
            clearInterval(this.inter)
        }
        if(this.inter2){
            clearInterval(this.inter2)
        }
    },
    created(){
        if(!checkPer("students|reports|daily")){
            this.$router.push('/NotPermitted')
            return false;
        }
        var g = this;
        g.getReport()
        $.post(api + '/user/students/classes', {
            jwt: this.user.jwt
        }).then(function(r){
            g.loading = false
            if(r.status != 100){
                alert(r.response)
            }else{
                g.classes = r.response
            }
        }).fail(function(){
        })
        g.inter = setInterval(() => {
             if($("#qq").val() != last_q && $("#qq").val() && $("#qq").val() != "" || ($("#qq").val() == "" && last_q != "" && last_q != null)){
                g.show_search_loading = true;
             }
            if($("#ddate").val() != g.date){
                g.date = $("#ddate").val()
                g.getReport()
            }
        }, 100);
        var last_q = null;
        g.inter2 = setInterval(() => {
            g.show_search_loading = false;
            if($("#qq").val() != last_q && $("#qq").val() && $("#qq").val() != "" || ($("#qq").val() == "" && last_q != "" && last_q != null)){
                last_q = $("#qq").val();
                g.searchStudent()
            }
        }, 2000);
    },
    methods: {
        exportExcel(){
            const wb = XLSX.utils.book_new(), g = this;
            const Heading = [
                [
                    "م",
                    "الاسم",
                    "الصف",
                    "الفصل",
                    "الحالة",
                    "توقيت الحضور",
                    "توقيت الانصراف"
                ]
            ];
            var xx = [];
            g.reports.forEach(function(a, i){
                xx.push([
                    i+1,
                    a.name,
                    a.classname,
                    a.classroom,
                    a.status?.toString().replace("0", "خارج وقت الدوام")
                    .replace("1", "حاضر")
                    .replace("2", "متأخر")
                    .replace("3", "غائب")
                    .replace("5", "غائب بعذر")
                    .replace("6", "اجازة") + (a.status == '5' ? `\n${a.reason}` : ''),
                    a?.join?.time,
                    (a?.leave?.time ?? '') + (a?.leave?.endex ? `*مستأذن*` : ''),
                ])
            })
            
            // creating sheet and adding data from 2nd row of column A.
            // leaving first row to add Heading
            const ws = XLSX.utils.json_to_sheet(xx, { origin: 'A2', skipHeader: true });
                
            // adding heading to the first row of the created sheet.
            // sheet already have contents from above statement.
            XLSX.utils.sheet_add_aoa(ws, Heading, { origin: 'A1' });
                
            // appending sheet with a name
            XLSX.utils.book_append_sheet(wb, ws, 'Records');
                
            const fileContent = XLSX.writeFile(wb, $("title").first().text().split("—") + ".xlsx");
        },
        ordertable(m){
            var x = this.reports, g = this;
            if(g.last_sort == m){
                g.xa = g.xa == 1 ? -1 : 1;
                g.xb = g.xb == -1 ? 1 : -1;
            }
            g.last_sort = m;
            function compare( a, b ) {
                if ( a[m] < b[m] ){
                    return g.xa;
                }
                if ( a[m] > b[m] ){
                    return g.xb;
                }
                return 0;
            }
            x.sort(compare);
            this.reports = []
            var g = this;
            setTimeout(() => {
                g.reports = JSON.parse(JSON.stringify(x))
            }, 100);
        },
        ex(r){
            if(r.status){
                if(r.endex){
                    return 'yes'
                }
            }
        },
        changeAllNoorStatuses(){
            var val = $("#changeAllNoorStatuses").val();
            var g = this;
            $(".noorStudents tr").each(function(a){
                $(this).find("select").val(val)
            })
        },
        changeAllNoorStatuses2(){
            var val = $("#changeAllNoorStatuses2").val();
            var g = this;
            $(".noorStudents tr").each(function(a){
                $(this).find("select").val(val)
            })
        },
        addGhiabN__a55ca6xx5a(){
            if (confirm("متأكد من أنك اخترت نوع المخالفة لجميع الطلاب؟")) {
            if ($("#addGhiab tbody tr").length) {

                var students = [];

                $("#addGhiab tbody tr").each(function () {

                    students.push({
                        "name": $(this).find('td').first().text().trim(),
                        "status": $(this).find('select').val()
                    });

                });

                chrome.storage.local.set({ "job2": "add-ghiab" }, function () {
                    
                            setTimeout(function(){
                    chrome.storage.local.remove(['late'], function(){
                            setTimeout(function(){
                        chrome.storage.local.set({ "type2": "day" }, function () {

                            setTimeout(function(){
                                chrome.storage.local.set({ "stage": $("#stage").val() }, function () {

                            setTimeout(function(){
                                chrome.storage.local.set({ "students2": students }, function () {

                                    if (confirm('برجاء الذهاب لصفحة "إدخال السلوك والمواظبة" في نور\n\nهل تريد الذهاب لموقع نور الآن؟')) {
                                        window.open('https://noor.moe.gov.sa/');
                                    }

                                });

                            }, 50)
                            });
                            }, 50)

                        });
                            }, 50)
                    })

                            }, 50)

                });

                } else {

                alert("لا يوجد أي طلاب حالتهم غياب يوم كامل.");

                }
            }
        },
        addGhiabN__a55ca6xx5a2(){
        if (confirm("متأكد من أنك اخترت نوع المخالفة لجميع الطلاب؟")) {

                    if ($("#addLate tbody tr").length) {

                        var students = [];

                        $("#addLate tbody tr").each(function () {

                            students.push({
                                "name": $(this).find('td').first().text().trim(),
                                "status": $(this).find('select').val()
                            });

                        });

                        chrome.storage.local.set({ "job2": "add-ghiab" }, function () {

                            chrome.storage.local.set({ "type2": "day" }, function () {

                                chrome.storage.local.set({ "late": "true" }, function () {
                                        
                                    chrome.storage.local.set({ "stage": $("#stage2").val() }, function () {
            
                                        chrome.storage.local.set({ "students2": students }, function () {
            
                                            if (confirm('برجاء الذهاب لصفحة "إدخال السلوك والمواظبة" في نور\n\nهل تريد الذهاب لموقع نور الآن؟')) {
                                                window.open('https://noor.moe.gov.sa/');
                                            }
            
                                        });
            
                                    });
            
                                });

                            });

                        });

                    } else {

                        alert("لا يوجد أي طلاب حالتهم غياب يوم كامل.");

                    }

        }
        },
        getReport(){
            var g = this;
            if(g.q == "" || !g.q){
                g.loading = true;
            }
            var be = $(document).scrollTop();
            g.reports = [];
            $.post(api + '/user/students/reports/daily', {
                jwt: this.user.jwt,
                date: g.date,
                classname: g.classname,
                status: g.status,
                q: g.q
            }).then(function(r){
                g.loading = false
                if(r.status != 100){
                    alert(r.response)
                }else{
                    g.reports = r.response
                    g.pending = r.pending
                    g.after = r.after
                    setTimeout(() => {
                        $('html, body').animate({scrollTop: be}, 0);
                    }, 100);
                }
            }).fail(function(){
                setTimeout(function(){
                    g.getReport()
                }, 500)
            })
        },
        refreshArr(){
            this.reports = JSON.parse(JSON.stringify(this.reports))
        },
        upldateReport(report, time, _id){
            var g = this;
            $.post(api + '/user/students/reports/update-report', {
                jwt: this.user.jwt,
                report_id: _id,
                status: report.status,
                time: time,
                reason: report.reason,
                number: report.number,
                date: g.date,
                name: report.name,
                from: "manual",
            }).then(function(r){
                g.loading = false
                if(r.status != 100){
                    alert(r.response)
                }else{
                    g.getReport()
                }
            }).fail(function(){
            })
        },
        print(status=null){
            var g = this;
            var win = window.open("", "")
            var s = "الحضور والغياب";
            if(status == '1'){
                s = "الحضور"
            }
            if(status == '2'){
                s = "التأخر"
            }
            if(status == '3'){
                s = "الغياب بدون عذر"
            }
            if(status == '1,2'){
                s = "الحضور والتأخر"
            }
            if(status == '5'){
                s = "الغياب بعذر"
            }
            if(status == 'ex'){
                s = "الإستئذان"
            }
            var q = ""
            if(status == 'ex'){
                q = `
                tbody tr:not([ex='yes']) {
                    display:none
                }
                `
            }else{
                if(status == null){

                }else{
                    if(status == "1,2"){
                        q = `
                        tbody tr:not([status='2']):not([status='1']) {
                            display:none
                        }`
                    }else{
                        q = `tbody tr:not([status='${status}']) {
                        display:none
                    }`
                    }
                }
            }
            var html = $("#table").parent().html();
            win.document.write(`
            <html dir='rtl'>
                <head>
                    <style>
                        table, td, th {
                        border: 1px solid;
                        }
                        tr{
                            text-align: center
                        }
                        table {
                        width: 100%;
                        border-collapse: collapse;
                    }
                    th{
                        background: #eee
                    }
                    .hidemex{
                        display:none
                    }
                    ${q}
                </style>
                </head>
                <body>
                    <div class='row' style='width: 100%; padding: 0px; position: relative;'>
                    <div class='col-4 text-center' style='width: 30%; text-align: center; display: inline-block; position: relative; top: -10px'>
                    <h5>
                        المملكة العربية السعودية
                        <br><br>وزارة التعليم
                    </h5>
                    </div>
                    <div class='col-4 text-center' style='width: 30%; text-align: center; display: inline-block'>
                        <img style='width: 100px; position: relative; top: 20px' src='https://static.arrajol.com/users/user223141/%D8%B4%D8%B9%D8%A7%D8%B1-%D9%88%D8%B2%D8%A7%D8%B1%D8%A9-%D8%A7%D9%84%D8%AA%D8%B9%D9%84%D9%8A%D9%85-%D8%A7%D9%84%D8%AC%D8%AF%D9%8A%D8%AF-%D8%B4%D9%81%D8%A7%D9%81.png'>
                        <center><h5 class='text-danger'>
                            تقرير ${s} بتاريخ ${g.date}
                        </h5></center>
                    </div>
                    <div class='col-4 text-center' style='width: 30%; text-align: center; display: inline-block; position: relative; top: -20px'>
                    <h5>
                        ${g.user.name}
                    </h5>
                    </div>
                    ${html}
                </body>
            </html>
            `)
            $(win.document).find("tbody tr:visible").each(function(i){
                $(this).find("td").first().html(i+1)
            })
            win.document.close()
            setTimeout(() => {
                win.print()
            }, 1000);
        },
        addBulkReasonNow(){
            var g = this;
            var done = []
            g.bulk_reason_selected_students.forEach(element => {
                $.post(api + '/user/students/reports/update-report', {
                    jwt: g.user.jwt,
                    report_id: element._id =='-' ? null : element._id,
                    status: '5',
                    time: element?.join?.time ?? g.date,
                    reason: g.bulk_reason_selected,
                    number: element.number,
                    date: g.date,
                    name: element.name,
                    from: "manual"
                }).then(function(r){
                    g.loading = false
                    if(r.status != 100){
                        alert(r.response)
                    }else{
                        done++
                        if(done == g.bulk_reason_selected_students.length){
                            g.bulk_reason_selected_students = []
                            g.getReport()
                            $("#reason-bulk___BV_modal_header_ > button").click()
                        }
                    }
                }).fail(function(){
                })
            });
        },
        searchStudent(){
            var g = this;
            if(g.q == "" || !g.q){
                g.loading = true;
            }
            g.reports = []
            $.post(api + '/user/students/reports/daily', {
                jwt: this.user.jwt,
                date: g.date,
                q: this.q
            }).then(function(r){
                g.loading = false
                if(r.status != 100){
                    alert(r.response)
                }else{
                    g.reports = r.response
                }
            }).fail(function(){
                setTimeout(function(){
                    g.getReport()
                }, 500)
            })
        },
        repair(){
            var g = this;
            g.loading = true;
            $("#repair___BV_modal_header_ > button").click()
            $.post(api + '/user/students/reports/repair', {
                jwt: this.user.jwt,
                date: g.date
            }).then(function(r){
                g.loading = false
                if(r.status != 100){
                    alert(r.response)
                }else{
                    alert("تم التصحيح بنجاح", 100)
                    g.getReport()
                }
            }).fail(function(){
                g.loading = false
                alert('حدث خطأ', 200)
            })
        }
    }
}
</script>

<style>
[dir] .dropdown-menu{
    min-width: 300px;
}
.hoverme span{
    transition: transform .2s;
}
.hoverme span:hover{
    transform: scale(1.1);
}
</style>